import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    gridNavApiKey:null
  },

  getters: {
    gridNavApiKey: state => state.gridNavApiKey,
  },

  mutations: {
    setGridNavApiKey(state, gridNavApiKey) {
      state.gridNavApiKey = gridNavApiKey;
    }
  
  },

  actions: {
    getGridNavApiKey({
      commit
    },partnerId) {
      commit("setIsLoading", true, {
        root: true
      });

      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "partnerGridNavApiKey/"+partnerId)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });
              commit("setGridNavApiKey", response.data.data);
          }
        })
        .catch(() => {
        });
    },

    sendRegenerateGridNavApiKeyRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .post(VUE_APP_API_URL + "partnerGridNavApiKey", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });
              commit("setGridNavApiKey", response.data.data);
          }


        });
    },
  }
};