import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    businessMapLocations:[]
  },

  getters: {
    businessMapLocations: state => state.businessMapLocations
  },

  mutations: {
    setBusinessMapLocations(state, businessMapLocations) {
      state.businessMapLocations = businessMapLocations;
    }
  
  },

  actions: {
  

    getBusinessLocationsCoordinates({
      commit
    },partnerId) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      commit("setBusinessMapLocations",[]);
     return axios
        .get(VUE_APP_API_URL + 'getPartnerGoogleMapLocationsCoordinates/'+partnerId)
       
        .then(response => {
          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

            if (response && response.data) {
              commit("setIsSuccess", true, {
                root: true
              });
                commit("setBusinessMapLocations", response.data.data);
            }
        })
        .catch(() => {
        });
    }

  }
};