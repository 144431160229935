import axios from "axios";
import NProgress from 'nprogress';
import '../../node_modules/nprogress/nprogress.css'
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    errors: [],
    userData: null,
    isLoading: false,
    isSuccess: false,
    isResending: false
  },

  getters: {
    user: state => state.userData,
    errors: state => state.errors,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess,
    isResending: state => state.isResending
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    setUserData(state, user) {
      state.userData = user;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setIsResending(state, isResending) {
      state.isResending = isResending;
    }
  },

  actions: {
    getUserData({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      axios
        .get(VUE_APP_API_URL + "me")
        .then(response => {
          NProgress.done();
          if (response && response.data) {

            let res = response.data;
            commit("setUserData", res.data);
            localStorage.removeItem("isEmailVerified");
            if (res.data.isEmailVerified) {
              localStorage.setItem("isEmailVerified", true);
            }
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },

    deleteAccount({
      commit
    }) {

      commit("setErrors", {}, {
        root: true
      });
  
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .get(VUE_APP_API_URL + "deleteAccount")
        .then(response => {

          if (response && response.data) {
            localStorage.removeItem("authToken");
            let res = response.data;
            commit("setUserData", res.data);

          }
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },
    sendLoginRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "login", data)
        .then(response => {
          if (response && response.data) {
            let res = response.data;
            commit("setUserData", res.data.user);
            
            commit("setIsLoading", false, {
              root: true
            });
            localStorage.setItem("authToken", res.data.accessToken);
            localStorage.removeItem("isEmailVerified");
            if (res.data.user.isEmailVerified) {
              localStorage.setItem("isEmailVerified", true);
            }
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
    sendUpdateProfileRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "updateProfile", data)
        .then(response => {
          if (response && response.data) {

            let res = response.data;
            commit("setUserData", res.data);
            commit("setIsSuccess", true, {
              root: true
            });

          }


        });
    },
  

    sendVerifyCodeRequest({
      commit
    }, data) {
       commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "email/verify", data)
        .then(response => {
          localStorage.removeItem("isEmailVerified");
          if (response && response.data) {

            let res = response.data;

            commit("setUserData", res.data);

            if (res.data.isEmailVerified) {
              localStorage.setItem("isEmailVerified", true);
            }

            commit("setIsSuccess", true, {
              root: true
            });

          }


        });
    },

    sendChangePasswordRequest({
      commit
    }, data) {
       commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
      .post(VUE_APP_API_URL + "reset-password", data)
      .then(response => {
        if (response && response.data) {
          let res = response.data;
          commit("setUserData", res.data.user);
          
          commit("setIsLoading", false, {
            root: true
          });
          localStorage.setItem("authToken", res.data.accessToken);
          localStorage.removeItem("isEmailVerified");
          if (res.data.user.isEmailVerified) {
            localStorage.setItem("isEmailVerified", true);
          }
          commit("setIsSuccess", true, {
            root: true
          });
        }


      });
    },


    sendResetLinkRequest({
      commit
    }, data) {
       commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "forget-password", data)
        .then(response => {

          if (response && response.data) {
      
            commit("setIsSuccess", true, {
              root: true
            });

          }


        });
    },


    sendRegisterRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "register", data)
        .then(response => {
          if (response && response.data) {
            let res = response.data;
            commit("setUserData", res.data.user);
            localStorage.setItem("authToken", res.data.accessToken);
            localStorage.removeItem("isEmailVerified");
            if (res.data.user.isEmailVerified) {
              localStorage.setItem("isEmailVerified", true);
            }
            commit("setIsSuccess", true, {
              root: true
            });
          }
        });
    },
    sendLogoutRequest({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios.post(VUE_APP_API_URL + "logout").then(() => {

        localStorage.removeItem("authToken");
        localStorage.removeItem("isEmailVerified");

      });
    },
    sendVerifyResendRequest({
      commit
    }) {
      commit("setIsResending", true, {
        root: true
      });
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      localStorage.removeItem("isEmailVerified");
      return axios.get(VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({
      dispatch
    }, hash) {
      return axios
        .get(VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    }
  }
};