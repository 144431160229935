import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import business from "./business";
import businessAddress from "./businessAddress";
import businessImage from "./businessImage";
import subscription from "./subscription";
import payment from "./payment";
import businessMapLocation from "./businessMapLocation";
import privacy from "./privacy";
import dashboard from "./dashboard";
import gridNavApiKey from "./gridNavApiKey";
import businessLocation from "./businessLocation";
import setting from "./setting";
import directorySearch from "./directorySearch";



Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    errors: [],
    data: [],
    changePasswordErrors: [],
    isLoading:false,
    isSuccess:false,
    isResending:false,
    changePasswordSuccess:false
  },

  getters: {
    changePasswordErrors: state => state.changePasswordErrors,
    errors: state => state.errors,
    data: state => state.data,
    isLoading: state => state.isLoading,
    isSuccess: state => state.isSuccess,
    isResending: state => state.isResending,
    changePasswordSuccess: state => state.changePasswordSuccess,
  },

  mutations: {
    setChangePasswordErrors(state, changePasswordErrors) {
      state.changePasswordErrors = changePasswordErrors;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setData(state, data) {
      state.data = data;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSuccess(state, isSuccess) {
      state.isSuccess = isSuccess;
    },
    setIsResending(state, isResending) {
      state.isResending = isResending;
    },
    setChangePasswordSuccess(state, changePasswordSuccess) {
      state.changePasswordSuccess = changePasswordSuccess;
    }
  },

  actions: {},

  modules: {
    auth,
    business,
    businessAddress,
    businessImage,
    subscription,
    payment,
    businessMapLocation,
    privacy,
    dashboard,
    gridNavApiKey,
    businessLocation,
    setting,
    directorySearch
  }
});
