import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    return next();
  } else {
    return next("/");
  }
};
const authVerified = (to, from, next) => {
  if (localStorage.getItem("authToken")) {

    if (!localStorage.getItem("isEmailVerified")) {
      return next("/verify");
    }else{
      return next();
    }
   
  } else {
    return next("/login");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
      return next();
  
  } else {
    return next("/login");
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Auth/Register.vue")
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Auth/ForgetPassword.vue")
  },
  {
    path: "/reset/password/:token",
    name: "ResetPassword",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Auth/ResetPassword.vue")
  },
  {
    path: "/verify",
    name: "ConfirmVerify",
    beforeEnter: auth,
    props: true,
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Auth/ConfirmVerify.vue")
  },
  {
    path: "/verify/:hash",
    name: "Verify",
    beforeEnter: auth,
    props: true,
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Auth/Verify.vue")
  },

  {
    path: "/",
    name: "Home",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "/" */ "../views/Pages/Home")
  },
  {
    path: "/myProfile",
    name: "MyProfile",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "myProfile" */ "../views/Pages/MyProfile")
  },
  {
    path: "/myBusiness/:partnerId",
    name: "MyBusiness",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "myBusiness" */ "../views/Pages/MyBusiness")
  },
  {
    path: "/mySubscription/:partnerId",
    name: "MySubscription",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "mySubscription" */ "../views/Pages/MySubscription")
  },
  {
    path: "/myContact/:partnerId",
    name: "MyContact",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "mySubscription" */ "../views/Pages/MyContact")
  },
  {
    path: "/createNewSubscription/:partnerId",
    name: "CreateNewSubscription",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createNewSubscription" */ "../views/Pages/CreateNewSubscription")
  },
  
  {
    path: "/myPayment/:partnerId",
    name: "MyPayment",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "myPayment" */ "../views/Pages/MyPayment")
  },

  {
    path: "/directorySearch",
    name: "DirectorySearch",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "myPayment" */ "../views/Pages/DirectorySearch")
  },
{
  path: "/mySetting/:partnerId",
  name: "MySetting",
  beforeEnter: authVerified,
  component: () => import(/* webpackChunkName: "mySetting" */ "../views/Pages/MySetting")
},
  
  {
    path: "/createBusiness",
    name: "CreateBusiness",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createBusiness" */ "../views/Stepper/CreateBusinessStepper.vue")
  },
 
  {
    path: "/createBusinessAddress/:partnerId",
    name: "CreateBussinessAddressStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createBusinessAddress" */ "../views/Stepper/CreateBusienssAddressStepper.vue")
  },
  {
    path: "/verifyBusinessAddressPhone/:partnerId",
    name: "VerifyBusinessAddressPhoneStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "verifyBusinessAddressPhone" */ "../views/Stepper/VerifyBusinessAddressPhoneStepper.vue")
  },
  
  {
    path: "/createBranchAddress/:partnerId/:parentPartnerId",
    name: "CreateBusinessBranchAddressStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createBranchAddress" */ "../views/Stepper/CreateBusinessBranchAddressStepper.vue")
  },

  {
    path: "/verifyBranchBusinessAddressPhone/:partnerId/:parentPartnerId",
    name: "VerifyBranchBusinessAddressPhoneStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "verifyBranchBusinessAddressPhone" */ "../views/Stepper/VerifyBranchBusinessAddressPhoneStepper.vue")
  },
  
   {
    path: "/createBusinessLocation/:partnerId",
    name: "CreateBusinessLocationStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createBusinessLocation" */ "../views/Stepper/CreateBusinessLocationStepper.vue")
  },
  {
    path: "/uploadBranchImages/:partnerId/:parentPartnerId",
    name: "UploadBusinessBranchImagesStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "uploadBranchImages" */ "../views/Stepper/UploadBusinessBranchImagesStepper.vue")
  },

  {
    path: "/uploadBusinessImages/:partnerId",
    name: "UploadBusinessImagesStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "uploadBusinessImages" */ "../views/Stepper/UploadBusinessImagesStepper.vue")
  },
  {
    path: "/createBusinessSubscription/:partnerId",
    name: "BusinessSubscriptionStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "createBusinessSubscription" */ "../views/Stepper/BusinessSubscriptionStepper.vue")
  },
  {
    path: "/editBusinessLocationStepper/:partnerId",
    name: "EditBusinessLocationStepper",
    beforeEnter: authVerified,
    component: () => import(/* webpackChunkName: "editBusinessLocationStepper" */ "../views/Stepper/EditBusinessLocationStepper.vue")
  }
  

];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});



export default router;
