import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    countries: [],
    states:[],
    cities:[],
    businesses:[]
  },

  getters: {
    countries: state => state.countries,
    states: state => state.states,
    cities: state => state.cities,
    businesses: state => state.businesses
  },

  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setStates(state, states) {
      state.states = states;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setBusinesses(state, businesses) {
      state.businesses = businesses;
    }
  
  },
  actions: {
    getBusinesses({
      commit
    }) {
     return axios
        .get(VUE_APP_API_URL + "directorySearch")
       
        .then(response => {
          
          if (response && response.data) {
           
              commit("setBusinesses", response.data.data);
          }
        })
    },
    
    nearByPartners({
      commit
    }) {
     return axios
        .get(VUE_APP_API_URL + "nearByPartners")
       
        .then(response => {
          
          if (response && response.data) {
           
              commit("setBusinesses", response.data.data);
          }
        })
    },
    nearByRelatedPartners({
      commit
    }) {
     return axios
        .get(VUE_APP_API_URL + "nearByRelatedPartners")
       
        .then(response => {
          
          if (response && response.data) {
           
              commit("setBusinesses", response.data.data);
          }
        })
    },
    
    partnerSearch({
      commit
    },obj) {
      let parms='';
      if(obj.businessCategoryIds.length > 0){
        let businessCategoryIdParms='';
        obj.businessCategoryIds.forEach( (el,i)=> {
          businessCategoryIdParms+='&businessCategoryIds['+i+']='+el;
        });
         parms= "textSearch="+obj.textSearch+"&countryId="+obj.countryId+"&stateId="+obj.stateId+"&cityId="+obj.cityId+""+businessCategoryIdParms;
      }else{
         parms= "textSearch="+obj.textSearch+"&countryId="+obj.countryId+"&stateId="+obj.stateId+"&cityId="+obj.cityId;
      }
    
   console.log(parms);
        
     return axios
        .get(VUE_APP_API_URL + 'directorySearch?'+parms)
       
        .then(response => {
          
            if (response && response.data) {
            
              commit("setBusinesses", response.data.data);
          }

        })
        .catch(() => {
        });
    },

    getCountries({
      commit
    },countryName) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      const endPoint = countryName?`countries?countryName=${countryName}`:"countries";
      return axios
        .get(VUE_APP_API_URL + endPoint)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,countryCode:obj.countryCode,countryName:obj.countryName});
              });
              commit("setCountries", results);
          }
        })
        .catch(() => {
        });
    },
    getStates({
      commit
    },obj) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      let endPoint='';

      if(obj.stateName && obj.countryId){
         endPoint = `states?countryId=${obj.countryId}&stateName=${obj.stateName}`;
      }else{

            if(obj.stateName){
              endPoint = `states?stateName=${obj.stateName}`;
            }else 
            if(obj.countryId){
              endPoint =`states?countryId=${obj.countryId}`;
            }else{
              endPoint = "states";
            }
      }
      
     return axios
        .get(VUE_APP_API_URL + endPoint)
       
        .then(response => {
           commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,stateName:obj.stateName,countryId:obj.countryId});
              });
              commit("setStates", results);
          }
        })
        .catch(() => {
        });
    },
    getCities({
      commit
    },obj) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      let endPoint='';

      if(obj.cityName && obj.stateId){
         endPoint = `cities?stateId=${obj.stateId}&cityName=${obj.cityName}`;
      }else{
        
            if(obj.cityName){
              endPoint = `cities?cityName=${obj.cityName}`;
            }else 
            if(obj.stateId){
              endPoint =`cities?stateId=${obj.stateId}`;
            }else{
              endPoint = "cities";
            }
      }

     return axios
        .get(VUE_APP_API_URL + endPoint)
       
        .then(response => {
           commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,cityName:obj.cityName,stateId:obj.stateId});
              });
              commit("setCities", results);
          }
        })
        .catch(() => {
        });
    },

  }
};