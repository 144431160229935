import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    businessImages: [],
    businessImageUrls:[]
  },

  getters: {
    businessImages: state => state.businessImages,
    businessImageUrls: state => state.businessImageUrls,
  },

  mutations: {
    setBusinessImages(state, businessImages) {
      state.businessImages = businessImages;
    },
    setBusinessImageUrls(state, businessImageUrls) {
      state.businessImageUrls = businessImageUrls;
    }

  },

  actions: {
    getBusinessImages({
      commit
    }, partnerId) {
      commit("setBusinessImages", []);
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "partnerImages?partnerId=" + partnerId)

        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results = [];
            response.data.data.forEach(obj => {
              results.push({
                id: obj.id,
                imageUrl: obj.imageUrl,
                partnerId: obj.partnerId
              });
            });

            commit("setBusinessImages", results);
          }
        })
        .catch(() => {});
    },

    getBusinessImageUrls({
      commit
    },partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      return axios
        .get(VUE_APP_API_URL + "partnerImages?partnerId=" + partnerId)

        .then(response => {

            let results = [];
            response.data.data.forEach(obj => {
              results.push(obj.imageUrl);
            });
          return results;
        })
        .catch(() => {});
    },

    deleteAllBusinessImages({
      commit
    }, partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .delete(VUE_APP_API_URL + "partnerDeleteAllImages?partnerId=" + partnerId)

        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response.status == 200) {
            commit("setIsSuccess", true, {
              root: true
            });

            commit("setBusinessImages", []);
          }
        })
        .catch(() => {});
    },
    deleteBusinessImage({
      commit
    }, id) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .delete(VUE_APP_API_URL + "partnerImages/" + id)

        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response.status == 200) {
            commit("setIsSuccess", true, {
              root: true
            });

          }
        })
        .catch(() => {});
    },
  }
  
};