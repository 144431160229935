import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    subscripionPlans:[],
    partnerSubscriptions:[]
  },

  getters: {
    subscripionPlans: state => state.subscripionPlans,
    partnerSubscriptions: state => state.partnerSubscriptions,
  },

  mutations: {
    setSubscripionPlans(state, subscripionPlans) {
      state.subscripionPlans = subscripionPlans;
    },
    setPartnerSubscriptions(state, partnerSubscriptions) {
      state.partnerSubscriptions = partnerSubscriptions;
    }
  
  },

  actions: {
    getSubscripionPlans({
      commit
    },partnerId) {
      commit("setIsLoading", true, {
        root: true
      });

      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "subscriptions?partnerId="+partnerId)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

            commit("setIsSuccess", true, {
              root: true
            });

              response.data.data.forEach(obj => {
                results.push({id:obj.id,title:obj.title,description:obj.description,price:obj.price,credit:obj.credit,isDefault:obj.isDefault,currency:obj.currency});
              });
              commit("setSubscripionPlans", results);
          }
        })
        .catch(() => {
        });
    },

    getPartnerSubscriptions({
      commit
    },partnerId) {
      commit("setIsLoading", true, {
        root: true
      });

      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "partnerSubscriptions?partnerId="+partnerId)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });

              commit("setPartnerSubscriptions", response.data.data);
          }
        })
        .catch(() => {
        });
    },
    
    sendUpdateSubscriptionRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      data['_method']='PUT';
      return axios
        .post(VUE_APP_API_URL + "partnerSubscriptions/"+data.id, data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          
          if (response && response.data) {
    
            commit("setIsSuccess", true, {
              root: true
            });
            
          }


        });
    },
    sendFreePartnerSubscriptions({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .post(VUE_APP_API_URL + "freeSubscription", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          
          if (response && response.data) {
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
  }
};