<template>
  <div id="app">
 <VOffline @detected-condition="amIOnline" online-class="online" offline-class="offline">
     
            <NoInternet  v-if="!online" />
         
            <transition :name="transitionName">
              <keep-alive>
                <router-view />
              </keep-alive>
            </transition>
     
    
    </VOffline>

   
    <notifications group="app" class="notification" position="top right" />

   
  </div>
</template>


<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NoInternet from './views/Common/Message/NoInternet'

  import {
    VOffline
  } from "v-offline";
  export default {
    components: {
      VOffline,
      NoInternet
    },
    data() {
      return {
        online: true,
        isPageReload:false,
        transitionName: 'moveInUp'
      }
    },
    watch: {
      '$route'(to, from) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'moveInUp' : 'moveInUp'
      },
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      animation() {
        return {
          /**
           * Animation function
           * 
           * Runs before animating, so you can take the initial height, width, color, etc
           * @param  {HTMLElement}  element  The notification element
           */
          enter(element) {
            let height = element.clientHeight
            return {
              // animates from 0px to "height"
              height: [height, 0],

              // animates from 0 to random opacity (in range between 0.5 and 1)
              opacity: [Math.random() * 0.5 + 0.5, 0]
            }
          },
          leave: {
            height: 0,
            opacity: 0
          }
        }
      },
    },

    mounted() {
      if (localStorage.getItem("authToken")) {
        this.getUserData();
      }
    },

    methods: {
      amIOnline(e) {
        this.online = e;
        if(!this.online){
          this.isPageReload=true;
        }
        if(this.isPageReload){
             if(this.online){
            this.$notify({
                      group: "app",
                      type: "success",
                      title: "Ooh!",
                      text: "It looks like you're connected to the internet!",
                      duration: 10000,
                      speed: 1000,
                   });

           }
        }
       
      },
      ...mapActions("auth", ["getUserData"])
    }
  };
</script>

<style scoped>
  .notification {

    margin: 70px 5px 5px;
    padding: 10px;
    font-size: 18px;
    color: #ffffff;

  }

  .moveInUp-enter-active {
    animation: fadeIn 1s ease-in;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
</style>