import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    payments:[],
    payment:null,
  },

  getters: {
    payments: state => state.payments,
    payment: state => state.payment
  },

  mutations: {
    setPayments(state, payments) {
      state.payments = payments;
    },
     setPayment(state, payment) {
      state.payment = payment;
    },
   
  
  },

  actions: {
    getPayments({
      commit
    },partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "payments?partnerId="+partnerId)
       
        .then(response => {
          commit("setIsSuccess", false, {
            root: true
          });
          console.log(response);
          if (response && response.data) {

              commit("setIsSuccess", true, {
                root: true
              });
              

              commit("setPayments", response.data.data);
          }
        })
        .catch(() => {
        });
    },
    sendMomoPayRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "payments", data)
        .then(response => {
          commit("setIsSuccess", false, {
            root: true
          });
          commit("setIsSuccess", false, {
            root: true
          });
          console.log(response.data.data);
          
          if (response && response.data) {
    
            commit("setIsSuccess", true, {
              root: true
            });
            commit("setPayment", response.data.data);
          }


        });
    },
    //checkPaymentStatusRequest
    checkPaymentStatusRequest({
      commit
    }, id) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .get(VUE_APP_API_URL + "payments/"+id)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          
          if (response && response.data) {
    
            console.log(response.data.data);
            commit("setIsSuccess", response.data.data.paymentStatus == 'successful'?true:false,{
              root: true
            });
          }


        });
    },
  }
};