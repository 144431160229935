import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    setting:null
  },

  getters: {
    setting: state => state.setting
  },

  mutations: {
    setSetting(state, setting) {
      state.setting = setting;
    }
  
  },
  actions: {
    getSetting({
      commit
    }) {
     return axios
        .get(VUE_APP_API_URL + "setting")
       
        .then(response => {
          
          if (response && response.data) {
           
              commit("setSetting", response.data);
          }
        })
    }
  }
};