import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    countries: [],
    states:[],
    cities:[],
    businessAddress:null,
    gridecode:null
  },

  getters: {
    businessAddress: state => state.businessAddress,
    countries: state => state.countries,
    states: state => state.states,
    cities: state => state.cities,
    gridecode: state => state.gridecode,
  },

  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setStates(state, states) {
      state.states = states;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setBusinessAddress(state, businessAddress) {
      state.businessAddress = businessAddress;
    },
    setGridecode(state, gridecode) {
      state.gridecode = gridecode;
    }
  
  },

  actions: {
    getCountries({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      return axios
        .get(VUE_APP_API_URL + "countries")
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,countryCode:obj.countryCode,countryName:obj.countryName});
              });
              commit("setCountries", results);
          }
        })
        .catch(() => {
        });
    },
    getStates({
      commit
    },countryId) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      const endPoint = countryId?`states?countryId=${countryId}`:"states";
     return axios
        .get(VUE_APP_API_URL + endPoint)
       
        .then(response => {
           commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,stateName:obj.stateName,countryId:obj.countryId});
              });
              commit("setStates", results);
          }
        })
        .catch(() => {
        });
    },
    getCities({
      commit
    },stateId) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      const endPoint = stateId?`cities?stateId=${stateId}`:"cities";
     return axios
        .get(VUE_APP_API_URL + endPoint)
       
        .then(response => {
           commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];

              response.data.data.forEach(obj => {
                results.push({id:obj.id,cityName:obj.cityName,stateId:obj.stateId});
              });
              commit("setCities", results);
          }
        })
        .catch(() => {
        });
    },
    getBusinessAddress({
      commit
    },partnerId) {

      commit("setErrors", {}, {
        root: true
      });

      commit("setBusinessAddress", null);
      commit("setGridecode", null);

      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + 'businessAddressByPartnerID/'+partnerId)
       
        .then(async response => {
           commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

            if (response && response.data) {
              commit("setIsSuccess", true, {
                root: true
              });
              await  commit("setBusinessAddress", response.data.data);
            }
        })
        .catch(() => {
        });
    },

    partnerGridSearch({
      commit
    },data) {

      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      commit("setGridecode",{});
     return axios
        .get(VUE_APP_API_URL + 'partnerGridSearch?gridCode='+data.gridCode+"&countryCode="+data.countryCode)
       
        .then(response => {
          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

            if (response && response.data) {
              commit("setIsSuccess", true, {
                root: true
              });
                commit("setGridecode", response.data.data);
            }
        })
        .catch(() => {
        });
    },

    

    sendBusinessAddressRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      
      
      return axios
        .post(VUE_APP_API_URL + "partnerAddresses", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });
       
          
          if (response && response.data) {
        
            commit("setBusinessAddress", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
    updateBusinessAddressRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      data['_method']='PUT';

      return axios
        .post(VUE_APP_API_URL + "partnerAddresses/"+data.id, data)
        .then(response => {
          commit("setIsLoading", false, {
            root: true
          });
          commit("setIsSuccess", false, {
            root: true
          });
         
          if (response && response.data) {
            commit("setBusinessAddress", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
    //sendVerifyTelephone

    sendVerifyTelephone({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      data['_method']='PUT';
      return axios
        .post(VUE_APP_API_URL + "verifyTelephone/"+data.id, data)
        .then(response => {
          commit("setIsLoading", false, {
            root: true
          });
          commit("setIsSuccess", false, {
            root: true
          });
         
          if (response && response.data) {
            commit("setBusinessAddress", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }

        });
    },
    //sendResendVerifyTelephone

    sendResendVerifyTelephone({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      data['_method']='PUT';
      return axios
        .post(VUE_APP_API_URL + "resendTelephoneVerificationSms/"+data.id, data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });
         
          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });
            commit("setData", response, {
              root: true
            });
            
          }


        });
    },

  }
};