import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    businessLocation: null,
    businessCategories:[],
    businessLocations:[],

  },

  getters: {
    businessCategories: state => state.businessCategories,
    businessLocation: state => state.businessLocation,
    businessLocations: state => state.businessLocations
  },

  mutations: {
   
    setBusinessCategories(state, businessCategories) {
      state.businessCategories = businessCategories;
    },
    setBusinessLocation(state, businessLocation) {
      state.businessLocation = businessLocation;
    },
    setBusinessLocations(state, businessLocations) {
      state.businessLocations = businessLocations;
    }
  
  },

  actions: {
    getBusinessCategories({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      axios
        .get(VUE_APP_API_URL + "businessCategories")
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];
            commit("setIsSuccess", true, {
              root: true
            });

              response.data.data.forEach(obj => {
                results.push({id:obj.id,name:obj.description});
              });
              commit("setBusinessCategories", results);
          }
        })
        .catch(() => {
        });
    },
    getBusinessLocation({
      commit
    },partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "businessPartners/"+partnerId+"?hasBusinessPartner="+true)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });
          
            return response.data.data;
    
          
        })
        .catch(() => {
        });
    },
    //headQuarterId
    getBusinessLocations({
      commit
    },partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "businessLocations/"+partnerId+"?hasBusinessPartner="+true)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });
          
          if (response && response.data) {
            commit("setBusinessLocations", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(() => {
        });
    },
  

    sendDeleteBusinessRequest({
      commit
    }, partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .delete(VUE_APP_API_URL + "businessPartners/"+partnerId)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
         
            commit("setBusinessLocation", null);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
    sendBusinessLocationRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      
      return axios
        .post(VUE_APP_API_URL + "businessPartners", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
         
            commit("setBusinessLocation", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
    
    updateBusinessLocationRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      data['_method']='PUT';
      return axios
        .post(VUE_APP_API_URL + "businessPartners/"+data.partnerId, data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

        

          if (response && response.data) {

            commit("setIsSuccess", true, {
              root: true
            });

            commit("setBusinessLocation", response.data.data);
    
          }


        });
    },
   
  }
};