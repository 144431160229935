import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import NProgress from 'nprogress';
import '../node_modules/nprogress/nprogress.css'
import Notifications from 'vue-notification';
import { VueSpinners } from '@saeris/vue-spinners'
import VueCompositionApi from "@vue/composition-api";
import '@morioh/v-lightbox/dist/lightbox.css';
import Lightbox from '@morioh/v-lightbox'
import icons from "v-svg-icons";
import VTooltip from 'v-tooltip';
import Flutterwave from  'flutterwave-vue-v3'

window.Pusher = require('pusher-js');

Vue.config.productionTip = false;
Vue.prototype.$flutterwave_publicKey = "";
Vue.use(Notifications);
Vue.use(VueSpinners)
Vue.use(VueCompositionApi);
Vue.use(Lightbox);
Vue.use(VTooltip)
Vue.component("icon", icons);

var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.use(Flutterwave, { publicKey: window.localStorage.getItem('fwpk')} );
Vue.filter('truncate', filter);

axios.interceptors.response.use(
  response => response, error => {
    // store.commit("setIsLoading", false);
    // store.commit("setErrors", {});
    // store.commit("setErrors", {});
    // store.commit("businessLocation/setBusinessLocations", []);
    // store.commit("business/setCurrentBusiness", {});
    // store.commit("businessLocation/setBusinessLocation", {});
    // store.commit("businessAddress/setCountries", []);
    // store.commit("businessAddress/setStates", []);
    // store.commit("businessAddress/setBusinessAddress", {});
    // store.commit("businessAddress/setGridecode", {});
    // store.commit("businessImage/setBusinessImages", []);
    // store.commit("payment/setPayment", {});
    // store.commit("payment/setPayments", []);
    // store.commit("subscription/setSubscripionPlans", []);
    
     if (error.response.status === 500 || 
      error.response.status === 404 ||
       error.response.status === 403 || 
       error.response.status === 400 || 
       error.response.status === 422) {
         
      if (error.response.data.errors) {
        store.commit("setErrors", error.response.data.errors);
        store.commit("setChangePasswordErrors", error.response.data.errors);
        
      } else {
        store.commit("setErrors", error.response.data);
        store.commit("setChangePasswordErrors", error.response.data);
      }
  }
    
    else if (error.response.status === 401) {
      store.commit("setErrors", error.response.data);
      store.commit("setChangePasswordErrors", error.response.data);
      store.commit("auth/setUserData", null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("isEmailVerified");
      router.push({
        name: "Login"
      });
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function (config) {

  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return config;
});

router.beforeResolve((to,from, next) => {
    NProgress.start()
   next()
})

router.afterEach(() => {
  NProgress.done()
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");