import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    dashboardStats:null
  },

  getters: {
    dashboardStats: state => state.dashboardStats,
  },

  mutations: {
    setDashboardStats(state, dashboardStats) {
      state.dashboardStats = dashboardStats;
    }
  
  },

  actions: {
    getDashboardStats({
      commit
    },partnerId) {
      commit("setIsLoading", true, {
        root: true
      });

      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "partnerDashboardStats/"+partnerId)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });
              commit("setDashboardStats", response.data.data);
          }
        })
        .catch(() => {
        });
    },
  }
};