import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    currentBusiness: null,
    businessCategories:[]

  },

  getters: {
    currentBusiness: state => state.currentBusiness,
    businessCategories: state => state.businessCategories
  },

  mutations: {
    setCurrentBusiness(state, business) {
      state.currentBusiness = business;
    },
    setBusinessCategories(state, businessCategories) {
      state.businessCategories = businessCategories;
    },
   
  },

  actions: {
    getBusinessCategories({
      commit
    }) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      axios
        .get(VUE_APP_API_URL + "businessCategories")
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
            let results =[];
            commit("setIsSuccess", true, {
              root: true
            });

              response.data.data.forEach(obj => {
                results.push({id:obj.id,name:obj.description});
              });
              commit("setBusinessCategories", results);
          }
        })
        .catch(() => {
        });
    },
    getPartnerBusiness({
      commit
    },partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      commit("setCurrentBusiness", null);
     return axios
        .get(VUE_APP_API_URL + "businessPartners/"+partnerId+"?hasBusinessPartner="+true)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });
          
          if (response && response.data) {
            commit("setCurrentBusiness", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }
        })
        .catch(() => {
        });
    },
   
    sendBusinessRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .post(VUE_APP_API_URL + "businessPartners", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
         
            commit("setCurrentBusiness", response.data.data);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },

    updateBusinessRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      
      data['_method']='PUT';
      
      return axios
        .post(VUE_APP_API_URL + "businessPartners/"+data.partnerId, data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

        

          if (response && response.data) {

            commit("setIsSuccess", true, {
              root: true
            });

            commit("setCurrentBusiness", response.data.data);
    
          }


        });
    },

    sendDeleteBusinessRequest({
      commit
    }, partnerId) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
      return axios
        .delete(VUE_APP_API_URL + "businessPartners/"+partnerId)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
         
            commit("setCurrentBusiness", null);
    
            commit("setIsSuccess", true, {
              root: true
            });
          }


        });
    },
  
    changePartnerLogo({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });
     
      
      return axios
        .post(VUE_APP_API_URL + "changePartnerBusinessLogo", data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          commit("setIsSuccess", false, {
            root: true
          });

          if (response && response.data) {
    
            commit("setIsSuccess", true, {
              root: true
            });

          }


        });
    },
  }
};