import axios from "axios";
import VUE_APP_API_URL from "./url"
export default {
  namespaced: true,

  state: {
    privacy:null
  },

  getters: {
    privacy: state => state.privacy,
  },

  mutations: {
    setPrivacy(state, privacy) {
      state.privacy = privacy;
    }
  
  },

  actions: {
    getPrivacy({
      commit
    },partnerId) {
      commit("setIsLoading", true, {
        root: true
      });

      commit("setIsSuccess", false, {
        root: true
      });

     return axios
        .get(VUE_APP_API_URL + "partnerPrivacy/"+partnerId)
       
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
            commit("setIsSuccess", true, {
              root: true
            });
              commit("setPrivacy", response.data.data);
          }
        })
        .catch(() => {
        });
    },

    sendUpdatePrivacyRequest({
      commit
    }, data) {
      commit("setErrors", {}, {
        root: true
      });
      commit("setIsLoading", true, {
        root: true
      });
      commit("setIsSuccess", false, {
        root: true
      });

      data['_method']='PUT';
      return axios
        .post(VUE_APP_API_URL + "partnerPrivacy/"+data.id, data)
        .then(response => {

          commit("setIsLoading", false, {
            root: true
          });

          if (response && response.data) {
           
              commit("setIsSuccess", true, {root: true});
              commit("setPrivacy", response.data.data);
          }


        });
    },
  }
};